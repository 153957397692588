import React, { ReactNode } from 'react';

type Props = {
  title: string;
  subtitle: ReactNode;
  actions: ReactNode;
};

const PageHeader = ({ title, subtitle, actions }: Props) => (
  <header className="bg-gray-50 py-8">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
      <div className="flex-1 min-w-0">
        <h1 className="mt-1 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{title}</h1>
        <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">{subtitle}</div>
      </div>
      <div className="mt-5 flex xl:mt-0 xl:ml-4">{actions}</div>
    </div>
  </header>
);

export { PageHeader };
